/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.js-backstretch').each( function() {

          backstretch_image_url = $( this ).data( 'backstretch-image-url' );

          if( backstretch_image_url !== ''){
            $( this ).backstretch( backstretch_image_url ).addClass('image-present');
          }

        });

        $( '.js-backstretch-section-block-1' ).backstretch( template_directory_uri + '/assets/images/section-one-bg.png' );
        $( '.js-backstretch-section-block-2' ).backstretch( template_directory_uri + '/assets/images/section-two-bg.png' );
        $( '.js-backstretch-section-block-3' ).backstretch( template_directory_uri + '/assets/images/section-three-bg.png' );
        $( '.js-backstretch-section-block-4' ).backstretch( template_directory_uri + '/assets/images/section-four-bg.png' );

        $('.litebox').liteBox({
          // background: 'rgba(0,0,0,.7)'
          background: '#4d4d4d'
        });

        $('.royalSlider').royalSlider({
          arrowsNav: true,
          loop: true,
          keyboardNavEnabled: true,
          controlsInside: false,
          imageScaleMode: 'fit',
          arrowsNavAutoHide: false,
          // autoScaleSlider: true,
          // autoScaleSliderWidth: 960,
          // autoScaleSliderHeight: 500,
          controlNavigation: 'none',
          thumbsFitInViewport: false,
          navigateByClick: true,
          startSlideId: 0,
          autoPlay: {
            enabled: true,
            delay: 5000
          },
          deeplinking: {
            enabled: true,
            change: false
          },
          /* size of all images http://help.dimsemenov.com/kb/royalslider-jquery-plugin-faq/adding-width-and-height-properties-to-images */
          imgWidth: 1022,
          imgHeight: 614
        });

        // caption animation
        if ($('.royalSlider').length){
          var $slider = $('.royalSlider').data('royalSlider');
          $slider.ev.on('rsBeforeAnimStart', function(event) {
              $('.rsGCaption').hide();
          });
          $slider.ev.on('rsAfterSlideChange', function(event) {
              $('.rsGCaption').show();
          });
        }

        // hover effect section blocks
        $('.section-block-title').find('a').hover(function(){
          $(this)
            .parent().parent().find('.section-block-background')
            .stop().animate({opacity: 1}, 200);

        }, function() {
            $(this)
              .parent().parent().find('.section-block-background')
              .stop().animate({opacity: 0.6}, 400);
        });

        // hover effect objects
        $('.object-block-title').find('a').hover(function(){
          $(this)
            .find('.object-block-caption')
            .stop().animate({paddingBottom: '0.9em'}, 200);

        }, function() {
            $(this)
              .find('.object-block-caption')
              .stop().animate({paddingBottom: 0}, 400);
        });

        $('.calendar-news .col-md-6').responsiveEqualHeightGrid();

        /**
         * Smooth scroll to inline anchors
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').stop().animate({
                        scrollTop: target.offset().top
                    }, 1500);
                    return false;
                }
            }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
